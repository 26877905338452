import { Logger } from "./logger";
import { Configuration } from "./configuration";

import { User, UserUpdatedEventArgs, UserUpdateReason } from "./user";
import { Network } from "./services/network";

import { NotificationTypes } from "./interfaces/notification-types";

import {
  TwilsockClient,
  InitRegistration,
  ConnectionState as TwilsockConnectionState,
  Transport,
} from "twilsock";
import {
  ChannelType,
  Notifications as NotificationClient,
} from "@twilio/notifications";
import { SyncClient } from "twilio-sync";
import { McsClient } from "@twilio/mcs-client";

import {
  Conversation,
  Conversations as ConversationsEntity,
} from "./data/conversations";

import { Users } from "./data/users";
import { TypingIndicator } from "./services/typing-indicator";
import { Paginator } from "./interfaces/paginator";
import { PushNotification } from "./push-notification";
import { deepClone, parseToNumber } from "./util";
import {
  Participant,
  ParticipantUpdatedEventArgs,
  ParticipantUpdateReason,
} from "./participant";
import {
  Message,
  MessageUpdatedEventArgs,
  MessageUpdateReason,
} from "./message";
import { TelemetryEventDescription, TelemetryPoint } from "twilsock";
import {
  validateTypesAsync,
  validateTypes,
  literal,
  nonEmptyString,
  pureObject,
  objectSchema,
  validateConstructorTypes,
  nonEmptyArray,
} from "@twilio/declarative-type-validator";
import { version as sdkVersion } from "../package.json";
import {
  ConversationUpdatedEventArgs,
  ConversationUpdateReason,
} from "./conversation";
import { CommandExecutor } from "./command-executor";
import { ConfigurationResponse } from "./interfaces/commands/configuration";
import { ReplayEventEmitter } from "@twilio/replay-event-emitter";
import { JSONValue } from "./types";
import { Media } from "./media";
import { CancellablePromise } from "@twilio/mcs-client";
import { deprecated, deprecationWarning } from "@twilio/deprecation-decorator";
import { ContentTemplate } from "./content-template";
import { ContentClient } from "./content-client";
import { ChannelMetadataClient } from "./channel-metadata-client";
import { MessageRecipientsClient } from "./message-recipients-client";

/**
 * Client events.
 */
type ClientEvents = {
  conversationAdded: (conversation: Conversation) => void;
  conversationJoined: (conversation: Conversation) => void;
  conversationLeft: (conversation: Conversation) => void;
  conversationRemoved: (conversation: Conversation) => void;
  conversationUpdated: (data: {
    conversation: Conversation;
    updateReasons: ConversationUpdateReason[];
  }) => void;
  participantJoined: (participant: Participant) => void;
  participantLeft: (participant: Participant) => void;
  participantUpdated: (data: {
    participant: Participant;
    updateReasons: ParticipantUpdateReason[];
  }) => void;
  messageAdded: (message: Message) => void;
  messageRemoved: (message: Message) => void;
  messageUpdated: (data: {
    message: Message;
    updateReasons: MessageUpdateReason[];
  }) => void;
  tokenAboutToExpire: () => void;
  tokenExpired: () => void;
  typingEnded: (participant: Participant) => void;
  typingStarted: (participant: Participant) => void;
  pushNotification: (pushNotification: PushNotification) => void;
  userSubscribed: (user: User) => void;
  userUnsubscribed: (user: User) => void;
  userUpdated: (data: {
    user: User;
    updateReasons: UserUpdateReason[];
  }) => void;
  stateChanged: (state: State) => void;
  initialized: () => void;
  initFailed: ({ error }: { error?: ConnectionError }) => void;
  connectionStateChanged: (state: TwilsockConnectionState) => void;
  connectionError: (data: ConnectionError) => void;
};

/**
 * Connection state of the client. Possible values are as follows:
 * * `'connecting'` - client is offline and connection attempt is in process
 * * `'connected'` - client is online and ready
 * * `'disconnecting'` - client is going offline as disconnection is in process
 * * `'disconnected'` - client is offline and no connection attempt is in
 * process
 * * `'denied'` - client connection is denied because of invalid JWT access
 * token. User must refresh token in order to proceed
 */
type ConnectionState = TwilsockConnectionState;

/**
 * State of the client. Possible values are as follows:
 * * `'failed'` - the client failed to initialize
 * * `'initialized'` - the client successfully initialized
 */
type State = "failed" | "initialized";

/**
 * Notifications channel type. Possible values are as follows:
 * * `'fcm'`
 * * `'apn'`
 */
type NotificationsChannelType = ChannelType;

/**
 * Level of logging.
 */
type LogLevel = "trace" | "debug" | "info" | "warn" | "error" | "silent";

/**
 * Conversations client options.
 */
interface ClientOptions {
  /**
   * The level of logging to enable.
   */
  logLevel?: LogLevel;

  /**
   * The cache capacity for channel metadata.
   */
  channelMetadataCacheCapacity?: number;

  /**
   * The cache capacity for message recipients.
   */
  messageRecipientsCacheCapacity?: number;

  region?: string;
  productId?: string;
  twilsockClient?: TwilsockClient;
  transport?: Transport;
  notificationsClient?: NotificationClient;
  syncClient?: SyncClient;
  typingIndicatorTimeoutOverride?: number;
  consumptionReportIntervalOverride?: string;
  httpCacheIntervalOverride?: string;
  userInfosToSubscribeOverride?: number;
  retryWhenThrottledOverride?: boolean;
  backoffConfigOverride?: Record<string, unknown>;
  Chat?: ClientOptions;
  IPMessaging?: ClientOptions;
  Sync?: Record<string, unknown>;
  Notification?: Record<string, unknown>;
  Twilsock?: Record<string, unknown>;
  clientMetadata?: Record<string, unknown>;
  initRegistrations?: InitRegistration[];
  disableDeepClone?: boolean;
  typingUri?: string;
  apiUri?: string;
  throwErrorsAlways?: boolean;
}

type ConnectionError = {
  terminal: boolean;
  message: string;
};

/**
 * Options for {@link Client.createConversation}.
 */
interface CreateConversationOptions {
  /**
   * Any custom attributes to attach to the conversation.
   */
  attributes?: JSONValue;

  /**
   * A non-unique display name of the conversation.
   */
  friendlyName?: string;

  /**
   * A unique identifier of the conversation.
   */
  uniqueName?: string;
}

/**
 * Client services.
 */
class ClientServices {
  commandExecutor!: CommandExecutor;
  twilsockClient!: TwilsockClient;
  users!: Users;
  notificationClient!: NotificationClient;
  network!: Network;
  typingIndicator!: TypingIndicator;
  syncClient!: SyncClient;
  mcsClient!: McsClient;
  transport!: Transport;
  contentClient!: ContentClient;
  channelMetadataClient!: ChannelMetadataClient;
  messageRecipientsClient!: MessageRecipientsClient;
}

/**
 * A client is the starting point to the Twilio Conversations functionality.
 */
@validateConstructorTypes(nonEmptyString, [pureObject, "undefined"])
class Client extends ReplayEventEmitter<ClientEvents> {
  /**
   * Fired when a conversation becomes visible to the client. The event is also
   * triggered when the client creates a new conversation.
   * Fired for all conversations that the client has joined.
   *
   * Parameters:
   * 1. {@link Conversation} `conversation` - the conversation in question
   * @event
   */
  public static readonly conversationAdded = "conversationAdded";

  /**
   * Fired when the client joins a conversation.
   *
   * Parameters:
   * 1. {@link Conversation} `conversation` - the conversation in question
   * @event
   */
  public static readonly conversationJoined = "conversationJoined";

  /**
   * Fired when the client leaves a conversation.
   *
   * Parameters:
   * 1. {@link Conversation} `conversation` - the conversation in question
   * @event
   */
  public static readonly conversationLeft = "conversationLeft";

  /**
   * Fired when a conversation is no longer visible to the client.
   *
   * Parameters:
   * 1. {@link Conversation} `conversation` - the conversation in question
   * @event
   */
  public static readonly conversationRemoved = "conversationRemoved";

  /**
   * Fired when the attributes or the metadata of a conversation have been
   * updated. During conversation's creation and initialization, this event
   * might be fired multiple times for same joined or created conversation as
   * new data is arriving from different sources.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the
   * following properties:
   *     * {@link Conversation} `conversation` - the conversation in question
   *     * {@link ConversationUpdateReason}[] `updateReasons` - array of reasons
   *     for the update
   * @event
   */
  public static readonly conversationUpdated = "conversationUpdated";

  /**
   * Fired when a participant has joined a conversation.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - the participant in question
   * @event
   */
  public static readonly participantJoined = "participantJoined";

  /**
   * Fired when a participant has left a conversation.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - the participant in question
   * @event
   */
  public static readonly participantLeft = "participantLeft";

  /**
   * Fired when a participant's fields have been updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the
   * following properties:
   *     * {@link Participant} `participant` - the participant in question
   *     * {@link ParticipantUpdateReason}[] `updateReasons` - array of reasons
   *     for the update
   * @event
   */
  public static readonly participantUpdated = "participantUpdated";

  /**
   * Fired when a new message has been added to the conversation on the server.
   *
   * Parameters:
   * 1. {@link Message} `message` - the message in question
   * @event
   */
  public static readonly messageAdded = "messageAdded";

  /**
   * Fired when a message is removed from the message list of a conversation.
   *
   * Parameters:
   * 1. {@link Message} `message` - the message in question
   * @event
   */
  public static readonly messageRemoved = "messageRemoved";

  /**
   * Fired when the fields of an existing message are updated with new values.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the
   * following properties:
   *     * {@link Message} `message` - the message in question
   *     * {@link MessageUpdateReason}[] `updateReasons` - array of reasons for
   *     the update
   * @event
   */
  public static readonly messageUpdated = "messageUpdated";

  /**
   * Fired when the token is about to expire and needs to be updated.
   * @event
   */
  public static readonly tokenAboutToExpire = "tokenAboutToExpire";

  /**
   * Fired when the token has expired.
   * @event
   */
  public static readonly tokenExpired = "tokenExpired";

  /**
   * Fired when a participant has stopped typing.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - the participant in question
   * @event
   */
  public static readonly typingEnded = "typingEnded";

  /**
   * Fired when a participant has started typing.
   *
   * Parameters:
   * 1. {@link Participant} `participant` - the participant in question
   * @event
   */
  public static readonly typingStarted = "typingStarted";

  /**
   * Fired when the client has received (and parsed) a push notification via one
   * of the push channels (apn or fcm).
   *
   * Parameters:
   * 1. {@link PushNotification} `pushNotification` - the push notification in
   * question
   * @event
   */
  public static readonly pushNotification = "pushNotification";

  /**
   * Fired when the client has subscribed to a user.
   *
   * Parameters:
   * 1. {@link User} `user` - the user in question
   * @event
   */
  public static readonly userSubscribed = "userSubscribed";

  /**
   * Fired when the client has unsubscribed from a user.
   *
   * Parameters:
   * 1. {@link User} `user` - the user in question
   * @event
   */
  public static readonly userUnsubscribed = "userUnsubscribed";

  /**
   * Fired when the properties or the reachability status of a user have been
   * updated.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the
   * following properties:
   *     * {@link User} `user` - the user in question
   *     * {@link UserUpdateReason}[] `updateReasons` - array of reasons for the
   *     update
   * @event
   */
  public static readonly userUpdated = "userUpdated";

  /**
   * @deprecated Use initialized or initFailed events instead
   * Fired when the state of the client has been changed.
   *
   * Parameters:
   * 1. {@link State} `state` - the new client state
   * @event
   */
  public static readonly stateChanged = "stateChanged";

  /**
   * Fired when the client has completed initialization successfully.
   * @event
   */
  public static readonly initialized = "initialized";

  /**
   * Fired when the client initialization failed.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the
   * following property:
   *     * Error? `error` - the initialization error if present
   * @event
   */
  public static readonly initFailed = "initFailed";

  /**
   * Fired when the connection state of the client has been changed.
   *
   * Parameters:
   * 1. {@link ConnectionState} `state` - the new connection state
   * @event
   */
  public static readonly connectionStateChanged = "connectionStateChanged";

  /**
   * Fired when the connection is interrupted for an unexpected reason.
   *
   * Parameters:
   * 1. object `data` - info object provided with the event. It has the
   * following properties:
   *     * boolean `terminal` - Twilsock will stop connection attempts if true
   *     * string `message` - the error message of the root cause
   *     * number? `httpStatusCode` - http status code if available
   *     * number? `errorCode` - Twilio public error code if available
   * @event
   */
  public static readonly connectionError = "connectionError";

  /**
   * Current version of the Conversations client.
   */
  public static readonly version: string = sdkVersion;

  /**
   * Logger instance.
   */
  private static readonly _logger = Logger.scope("Client");

  /**
   * Supported push notification channels.
   */
  private static readonly _supportedPushChannels: NotificationsChannelType[] = [
    "fcm",
    "apn",
  ];

  /**
   * Supported push data fields.
   */
  private static readonly _supportedPushDataFields = {
    conversation_sid: "conversationSid", // string
    conversation_title: "conversationTitle", // string
    message_sid: "messageSid", // string
    message_index: "messageIndex", // integer
    media_count: "mediaCount", // integer
    media: "media", // object
  };

  /**
   * Current version of the Conversations client.
   */
  public readonly version: string = sdkVersion;

  /**
   * Client connection state.
   */
  public connectionState: ConnectionState = "unknown";

  /**
   * Promise that resolves on successful initialization.
   */
  private _ensureReady!: Promise<void>;

  /**
   * Options passed to the client.
   */
  private readonly _options: Partial<ClientOptions>;

  /**
   * Client service objects.
   */
  private readonly _services: ClientServices;

  /**
   * The user of the client.
   */
  private readonly _myself: User;

  /**
   * Resolves the {@link Client._ensureReady} promise.
   */
  private _resolveEnsureReady!: () => void;

  /**
   * Rejects the {@link Client._ensureReady} promise.
   */
  private _rejectEnsureReady!: (err?: ConnectionError) => void;

  /**
   * The current token of the client.
   */
  private _fpaToken: string;

  /**
   * The constructed configuration object.
   */
  private _configuration!: Configuration;

  /**
   * The Conversations entity.
   */
  private _conversationsEntity!: ConversationsEntity;

  /**
   * Promise that resolves when initial conversations are fetched.
   */
  private _conversationsPromise!: Promise<ConversationsEntity>;

  /**
   * Returned Conversations Client instance is not yet fully initialized. Calling any
   * operations will block until it is. Use connection events to monitor when
   * client becomes fully available (connectionStateChanged with state
   * 'connected') or not available (connectionStateChange with state 'denied',
   * event tokenExpired, event connectionError).
   *
   * @param fpaToken Access token
   * @param options Options to customize the Client
   * @returns A not yet fully-initialized client.
   */
  public constructor(fpaToken: string, options: ClientOptions | null = {}) {
    super();

    this._fpaToken = fpaToken ?? "";
    this._options = options ?? {};

    if (!this._options.disableDeepClone) {
      let options: Partial<ClientOptions> = {
        ...this._options,
        transport: undefined,
        twilsockClient: undefined,
      };

      options = deepClone(options);
      options.transport = this._options.transport;
      options.twilsockClient = this._options.twilsockClient;

      this._options = options;
    }

    this._options.logLevel = this._options.logLevel ?? "silent";
    Client._logger.setLevel(this._options.logLevel);

    const productId = (this._options.productId = "ip_messaging");

    // Filling ClientMetadata
    this._options.clientMetadata = this._options.clientMetadata || {};

    if (!this._options.clientMetadata.hasOwnProperty("type")) {
      this._options.clientMetadata.type = "conversations";
    }

    if (!this._options.clientMetadata.hasOwnProperty("sdk")) {
      this._options.clientMetadata.sdk = "JS";
      this._options.clientMetadata.sdkv = sdkVersion;
    }

    // Enable session local storage for Sync
    this._options.Sync = this._options.Sync || {};

    if (typeof this._options.Sync.enableSessionStorage === "undefined") {
      this._options.Sync.enableSessionStorage = true;
    }

    if (this._options.region) {
      this._options.Sync.region = this._options.region;
    }

    if (!fpaToken) {
      throw new Error("A valid Twilio token should be provided");
    }

    this._services = new ClientServices();

    this._myself = new User("", "", null, this._services);

    const startTwilsock = !this._options.twilsockClient;

    // Create default init registrations if none were provided.
    // Otherwise, the outside party have to list all the init registrations they
    // need.
    // Init registrations passed to the Conversations client will be passed down
    // to the Sync client as well.
    if (!this._options.initRegistrations) {
      const initRegistration = new InitRegistration(productId);
      Client.populateInitRegistrations(initRegistration);
      this._options.initRegistrations = [initRegistration];
    }

    this._services.twilsockClient = this._options.twilsockClient =
      this._options.twilsockClient ??
      new TwilsockClient(fpaToken, productId, this._options);

    this._services.twilsockClient.on(Client.tokenAboutToExpire, () =>
      this.emit(Client.tokenAboutToExpire)
    );
    this._services.twilsockClient.on(Client.tokenExpired, () =>
      this.emit(Client.tokenExpired)
    );
    this._services.twilsockClient.on(Client.connectionError, (error) =>
      this.emit(Client.connectionError, error)
    );
    this._services.twilsockClient.on(
      "stateChanged",
      (state: ConnectionState) => {
        Client._logger.debug(
          `Handling stateChanged for ConversationsClient: new state ${state}`
        );
        if (state !== this.connectionState) {
          this.connectionState = state;
          this.emit(Client.connectionStateChanged, this.connectionState);
        }
      }
    );

    this._services.transport = this._options.transport = (this._options
      .transport ?? this._options.twilsockClient) as Transport;
    this._services.notificationClient = this._options.notificationsClient =
      this._options.notificationsClient ??
      new NotificationClient(fpaToken, this._options);
    this._services.syncClient = this._options.syncClient =
      this._options.syncClient ?? new SyncClient(fpaToken, this._options);

    const configurationOptions =
      options?.Chat || options?.IPMessaging || options || {};
    const region = configurationOptions.region || options?.region;
    const baseUrl: string =
      configurationOptions.apiUri ||
      configurationOptions.typingUri ||
      `https://aim.${region || "us1"}.twilio.com`;

    this._services.commandExecutor = new CommandExecutor(
      baseUrl,
      { transport: this._options.transport },
      productId
    );
    this._services.contentClient = new ContentClient(this._services);

    const emitFailed = (error?: ConnectionError): void => {
      this._rejectEnsureReady(error);
      this.emit(Client.stateChanged, "failed");
      this.emit(Client.initFailed, { error });
    };

    const emitDisconnected = () => {
      emitFailed({
        terminal: true,
        message: "Twilsock has disconnected.",
      });
      this._initializeEnsureReady(options?.throwErrorsAlways || false);
    };

    this._services.twilsockClient.once("connectionError", emitFailed);
    this._services.twilsockClient.once("disconnected", emitDisconnected);
    this._services.twilsockClient.once("connected", async () => {
      Client._logger.debug(`ConversationsClient started INITIALIZING`);
      this._services.twilsockClient.off("connectionError", emitFailed);
      this._services.twilsockClient.off("disconnected", emitDisconnected);
      try {
        const startupEvent = "conversations.client.startup";

        this._services.twilsockClient.addPartialTelemetryEvent(
          new TelemetryEventDescription(
            startupEvent,
            "Conversations client startup",
            new Date()
          ),
          startupEvent,
          TelemetryPoint.Start
        );

        await this._initialize();

        this._services.twilsockClient.addPartialTelemetryEvent(
          new TelemetryEventDescription("", "", new Date()),
          startupEvent,
          TelemetryPoint.End
        );
      } catch (err) {
        // Fail ChatClient if initialization is incomplete
        const connectionError = {
          terminal: true,
          message: err.message,
        };
        this._rejectEnsureReady(connectionError);
        this.emit(Client.stateChanged, "failed");
        this.emit(Client.initFailed, {
          error: connectionError,
        });
      }
    });

    this._initializeEnsureReady(options?.throwErrorsAlways || false);

    if (startTwilsock) {
      this._services.twilsockClient.connect();
    }
  }

  /**
   * Information of the logged-in user. Before client initialization, returns an
   * uninitialized user. Will trigger a {@link Client.userUpdated} event after
   * initialization.
   */
  public get user(): User {
    return this._myself;
  }

  /**
   * Client reachability state. Throws an error if accessed before the client
   * initialization was completed.
   */
  public get reachabilityEnabled(): boolean {
    if (!this._configuration) {
      throw new Error(
        "Reachability information could not yet be accessed as the client " +
          "has not yet been initialized. Subscribe to the 'stateChanged' event " +
          "to properly react to the client initialization."
      );
    }

    return this._configuration.reachabilityEnabled;
  }

  /**
   * @deprecated
   * Current token.
   * @internal
   */
  @deprecated("token")
  public get token(): string {
    return this._fpaToken;
  }

  /**
   * @deprecated Call constructor directly.
   *
   * Factory method to create a Conversations client instance.
   *
   * The factory method will automatically trigger connection.
   * Do not use it if you need finer-grained control.
   *
   * Since this method returns an already-initialized client, some of the events
   * will be lost because they happen *before* the initialization. It is
   * recommended that `client.onWithReplay` is used as opposed to `client.on`
   * for subscribing to client events. The `client.onWithReplay` will re-emit
   * the most recent value for a given event if it emitted before the
   * subscription.
   *
   * @param token Access token.
   * @param options Options to customize the client.
   * @returns Returns a fully initialized client.
   */
  @deprecated("Client.create()", "new Client()")
  @validateTypesAsync("string", ["undefined", pureObject])
  public static async create(
    token: string,
    options?: ClientOptions | null
  ): Promise<Client> {
    // The logic is as follows:
    // - If twilsock is not passed in, then the ConversationsClient constructor will call twilsock.connect() by itself
    //   and we do not need to do it here.
    // - If twilsock was passed in from the outside, but customer called ConversationsClient.create() then they are
    //   using an obsolete workflow and the startup sequence will never complete.
    if (options?.twilsockClient) {
      throw new Error(
        "Obsolete usage of ConversationsClient.create() " +
          "factory method: if you pass twilsock from the outside then you must " +
          "use ConversationsClient constructor and be prepared to work with " +
          "uninitialized client."
      );
    }

    const client = new Client(token, options);
    await client._ensureReady;

    return client;
  }

  /**
   * Static method for push notification payload parsing. Returns parsed push as
   * a {@link PushNotification} object.
   * @param notificationPayload Push notification payload.
   */
  @validateTypes(pureObject)
  public static parsePushNotification(notificationPayload): PushNotification {
    Client._logger.debug(
      "parsePushNotification, notificationPayload=",
      notificationPayload
    );

    // APNS specifics
    if (typeof notificationPayload.aps !== "undefined") {
      if (!notificationPayload.twi_message_type) {
        throw new Error(
          "Provided push notification payload does not contain Programmable Chat push notification type"
        );
      }

      const data = Client._parsePushNotificationChatData(notificationPayload);

      const apsPayload = notificationPayload.aps;
      let body: string | null;
      let title: string | null = null;
      if (typeof apsPayload.alert === "string") {
        body = apsPayload.alert || null;
      } else {
        body = apsPayload.alert?.body || null;
        title = apsPayload.alert?.title || null;
      }

      return new PushNotification({
        title,
        body,
        sound: apsPayload.sound || null,
        badge: apsPayload.badge || null,
        action: apsPayload.category || null,
        type: notificationPayload.twi_message_type,
        data: data,
      });
    }

    // FCM specifics
    if (typeof notificationPayload.data !== "undefined") {
      const dataPayload = notificationPayload.data;
      if (!dataPayload.twi_message_type) {
        throw new Error(
          "Provided push notification payload does not contain Programmable Chat push notification type"
        );
      }

      const data = Client._parsePushNotificationChatData(
        notificationPayload.data
      );
      return new PushNotification({
        title: dataPayload.twi_title || null,
        body: dataPayload.twi_body || null,
        sound: dataPayload.twi_sound || null,
        badge: null,
        action: dataPayload.twi_action || null,
        type: dataPayload.twi_message_type,
        data: data,
      });
    }

    throw new Error(
      "Provided push notification payload is not Programmable Chat notification"
    );
  }

  /**
   * Static method for parsing push notification chat data.
   * @param data Data to parse
   */
  private static _parsePushNotificationChatData(
    data: Record<string, unknown>
  ): Record<string, unknown> {
    const result: Record<string, unknown> = {};

    for (const key in Client._supportedPushDataFields) {
      const value = data[key];
      if (typeof value === "undefined" || value === null) {
        continue;
      }

      if (key === "message_index" || key === "media_count") {
        const number = parseToNumber(value);
        if (number !== null) {
          result[Client._supportedPushDataFields[key]] = number;
        }
        continue;
      }

      if (key === "media") {
        if (typeof value === "string") {
          try {
            result[Client._supportedPushDataFields[key]] = JSON.parse(value);
          } catch {
            Client._logger.debug("Media message notification parsing error");
          }
        }
        continue;
      }

      result[Client._supportedPushDataFields[key]] = value;
    }

    return result;
  }

  /**
   * Populate the client with init registrations.
   * @param reg The init registration to populate.
   */
  public static populateInitRegistrations(reg: InitRegistration) {
    reg.populateInitRegistrations([NotificationTypes.TYPING_INDICATOR]);
    SyncClient.populateInitRegistrations(reg);
  }

  /**
   * Gracefully shut down the client.
   */
  public async shutdown(): Promise<void> {
    await this._ensureReady;
    await this._services.twilsockClient.disconnect();
  }

  /**
   * Update the token used by the client and re-register with the Conversations services.
   * @param token New access token.
   */
  @validateTypesAsync(nonEmptyString)
  public async updateToken(token: string): Promise<Client> {
    await this._ensureReady;
    Client._logger.info("updateToken");

    if (this._fpaToken === token) {
      return this;
    }

    await this._services.twilsockClient.updateToken(token);
    await this._services.notificationClient.updateToken(token);
    await this._services.mcsClient.updateToken(token);
    this._fpaToken = token;

    return this;
  }

  /**
   * Get a known conversation by its SID.
   * @param conversationSid Conversation sid
   */
  @validateTypesAsync(nonEmptyString)
  public async getConversationBySid(
    conversationSid: string
  ): Promise<Conversation> {
    await this._ensureReady;
    await this._conversationsEntity.myConversationsRead.promise;

    let conversation = await this._conversationsEntity.getConversation(
      conversationSid
    );

    if (!conversation) {
      conversation = await this.peekConversationBySid(conversationSid);
      if (conversation) {
        deprecationWarning(
          "The method getConversationBySid is deprecated to retrieve conversations you're not part of. Use peekConversationBySid instead."
        );
      }
    }

    if (!conversation) {
      throw new Error(
        `Conversation with SID ${conversationSid} was not found.`
      );
    }

    return conversation;
  }

  /**
   * Peek a conversation by its SID.
   * @param conversationSid Conversation sid
   * @internal
   */
  @validateTypesAsync(nonEmptyString)
  public async peekConversationBySid(
    conversationSid: string
  ): Promise<Conversation> {
    await this._ensureReady;

    const conversation = await this._conversationsEntity.peekConversation(
      conversationSid
    );

    if (!conversation) {
      throw new Error(
        `Conversation with SID ${conversationSid} was not found.`
      );
    }

    return conversation;
  }

  /**
   * Get a known conversation by its unique identifier name.
   * @param uniqueName The unique identifier name of the conversation.
   */
  @validateTypesAsync(nonEmptyString)
  public async getConversationByUniqueName(
    uniqueName: string
  ): Promise<Conversation> {
    await this._ensureReady;
    await this._conversationsEntity.myConversationsRead.promise;
    const conversation =
      await this._conversationsEntity.getConversationByUniqueName(uniqueName);

    if (!conversation) {
      throw new Error(
        `Conversation with unique name ${uniqueName} was not found.`
      );
    }

    return conversation;
  }

  /**
   * Get the current list of all the subscribed conversations.
   */
  public async getSubscribedConversations(): Promise<Paginator<Conversation>> {
    await this._ensureReady;
    return this._conversationsPromise.then((conversations) =>
      conversations.getConversations()
    );
  }

  /**
   * Create a conversation on the server and subscribe to its events.
   * The default is a conversation with an empty friendly name.
   * @param options Options for the conversation.
   */
  @validateTypesAsync([
    "undefined",
    objectSchema("conversation options", {
      friendlyName: ["string", "undefined"],
      isPrivate: ["boolean", "undefined"],
      uniqueName: ["string", "undefined"],
    }),
  ])
  public async createConversation(
    options?: CreateConversationOptions
  ): Promise<Conversation> {
    await this._ensureReady;
    options = options || {};
    return this._conversationsPromise.then((conversationsEntity) =>
      conversationsEntity.addConversation(options)
    );
  }

  /**
   * Register for push notifications.
   * @param channelType Channel type.
   * @param registrationId Push notification ID provided by the FCM/APNS service
   * on the platform.
   */
  @validateTypesAsync(literal("fcm", "apn"), "string")
  public async setPushRegistrationId(
    channelType: NotificationsChannelType,
    registrationId: string
  ): Promise<void> {
    await this._ensureReady;
    this._subscribeToPushNotifications(channelType);
    this._services.notificationClient.setPushRegistrationId(
      channelType,
      registrationId
    );
    await this._services.notificationClient.commitChanges(); // Committing before this point is useless because we have no push id
  }

  /**
   * Unregister from push notifications.
   * @param channelType Channel type.
   * @deprecated Use removePushRegistrations() instead.
   */
  @validateTypesAsync(literal("fcm", "apn"))
  public async unsetPushRegistrationId(
    channelType: NotificationsChannelType
  ): Promise<void> {
    await this._ensureReady;
    this._unsubscribeFromPushNotifications(channelType);
    await this._services.notificationClient.commitChanges();
  }

  /**
   * Clear existing registrations directly using provided device token.
   * This is useful to ensure stopped subscriptions without resubscribing.
   *
   * This function goes completely beside the state machine and removes all
   * registrations.
   * Use with caution: if it races with current state machine operations,
   * madness will ensue.
   *
   * @param channelType Channel type.
   * @param registrationId Push notification ID provided by the FCM/APNS service
   * on the platform.
   */
  @validateTypesAsync(literal("fcm", "apn"), nonEmptyString)
  public async removePushRegistrations(
    channelType: ChannelType,
    registrationId: string
  ): Promise<void> {
    // do not await this._ensureReady() here - it could be called at any moment
    await this._services.notificationClient.removeRegistrations(
      channelType,
      registrationId
    );
  }

  /**
   * Parse a push notification payload.
   */
  public parsePushNotification = Client.parsePushNotification;

  /**
   * Handle push notification payload parsing and emit the
   * {@link Client.pushNotification} event on this {@link Client} instance.
   * @param notificationPayload Push notification payload
   */
  @validateTypesAsync(pureObject)
  public async handlePushNotification(notificationPayload): Promise<void> {
    await this._ensureReady;
    Client._logger.debug(
      "handlePushNotification, notificationPayload=",
      notificationPayload
    );
    this.emit(
      "pushNotification",
      Client.parsePushNotification(notificationPayload)
    );
  }

  /**
   * Gets a user with the given identity. If it's in the subscribed list, then
   * return the user object from it;
   * if not, then subscribe and add user to the subscribed list.
   * @param identity Identity of the user.
   * @returns A fully initialized user.
   */
  @validateTypesAsync(nonEmptyString)
  public async getUser(identity: string): Promise<User> {
    await this._ensureReady;
    return this._services.users.getUser(identity);
  }

  /**
   * Get a list of subscribed user objects.
   */
  public async getSubscribedUsers(): Promise<Array<User>> {
    await this._ensureReady;
    return this._services.users.getSubscribedUsers();
  }

  /**
   * Get content URLs for all media attachments in the given set of media sids
   * using a single operation.
   * @param mediaSids Set of media sids to query for the content URL.
   */
  @validateTypesAsync(nonEmptyArray("strings", "string"))
  public getTemporaryContentUrlsForMediaSids(
    mediaSids: string[]
  ): CancellablePromise<Map<string, string>> {
    return new CancellablePromise(async (resolve, reject, onCancel) => {
      if (!this._services.mcsClient || !mediaSids) {
        reject(new Error("Media Content Service is unavailable"));
        return;
      }

      const request =
        this._services.mcsClient.mediaSetGetContentUrls(mediaSids);

      onCancel(() => {
        request.cancel();
      });

      try {
        const urls = await request;
        resolve(urls);
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * Get content URLs for all media attachments in the given set using a single
   * operation.
   * @param contentSet Set of media attachments to query content URLs.
   */
  @validateTypesAsync(nonEmptyArray("media", Media))
  public getTemporaryContentUrlsForMedia(
    contentSet: Media[]
  ): CancellablePromise<Map<string, string>> {
    // We ignore existing mcsMedia members of each of the media entries.
    // Instead, we just collect their sids and pull new descriptors from a
    // mediaSet GET endpoint.
    const sids = contentSet.map((m) => m.sid);
    return this.getTemporaryContentUrlsForMediaSids(sids);
  }

  /**
   * Returns rich content templates belonging to the account. Rich content
   * templates can be created via the Twilio console or the REST API.
   */
  public async getContentTemplates(): Promise<Readonly<ContentTemplate[]>> {
    await this._ensureReady;
    return await this._services.contentClient.getContentTemplates();
  }

  /**
   * Initialize the client.
   */
  private async _initialize() {
    const configurationResponse =
      await this._services.commandExecutor.fetchResource<
        void,
        ConfigurationResponse
      >("Client/v2/Configuration");

    this._configuration = new Configuration(
      this._options as ClientOptions,
      configurationResponse,
      Client._logger
    );

    this._services.channelMetadataClient = new ChannelMetadataClient(
      this._services,
      this._configuration
    );
    this._services.messageRecipientsClient = new MessageRecipientsClient(
      this._services,
      this._configuration
    );

    this._myself._resolveInitialization(
      this._configuration,
      this._configuration.userIdentity,
      this._configuration.userInfo,
      true
    );

    this._services.typingIndicator = new TypingIndicator(
      this.getConversationBySid.bind(this),
      this._configuration,
      this._services
    );
    this._services.network = new Network(this._configuration, this._services);

    this._services.users = new Users(
      this._myself,
      this._configuration,
      this._services
    );
    this._services.users.on("userSubscribed", (user) => {
      this.emit("userSubscribed", user);
    });
    this._services.users.on("userUpdated", (args: UserUpdatedEventArgs) =>
      this.emit("userUpdated", args)
    );
    this._services.users.on("userUnsubscribed", (user) => {
      this.emit("userUnsubscribed", user);
    });

    this._conversationsEntity = new ConversationsEntity(
      this._configuration,
      this._services
    );

    this._conversationsEntity.on("conversationAdded", (conversation) => {
      this.emit("conversationAdded", conversation);
    });
    this._conversationsEntity.on("conversationRemoved", (conversation) => {
      this.emit("conversationRemoved", conversation);
    });
    this._conversationsEntity.on("conversationJoined", (conversation) => {
      this.emit("conversationJoined", conversation);
    });
    this._conversationsEntity.on("conversationLeft", (conversation) => {
      this.emit("conversationLeft", conversation);
    });
    this._conversationsEntity.on(
      "conversationUpdated",
      (args: ConversationUpdatedEventArgs) =>
        this.emit("conversationUpdated", args)
    );

    this._conversationsEntity.on("participantJoined", (participant) => {
      this.emit("participantJoined", participant);
    });
    this._conversationsEntity.on("participantLeft", (participant) => {
      this.emit("participantLeft", participant);
    });
    this._conversationsEntity.on(
      "participantUpdated",
      (args: ParticipantUpdatedEventArgs) =>
        this.emit("participantUpdated", args)
    );

    this._conversationsEntity.on("messageAdded", (message) =>
      this.emit("messageAdded", message)
    );
    this._conversationsEntity.on(
      "messageUpdated",
      (args: MessageUpdatedEventArgs) => this.emit("messageUpdated", args)
    );
    this._conversationsEntity.on("messageRemoved", (message) =>
      this.emit("messageRemoved", message)
    );

    this._conversationsEntity.on("typingStarted", (participant) =>
      this.emit("typingStarted", participant)
    );
    this._conversationsEntity.on("typingEnded", (participant) =>
      this.emit("typingEnded", participant)
    );

    this._conversationsPromise = this._conversationsEntity
      .fetchConversations()
      .then(() => this._conversationsEntity)
      .catch((error) => {
        throw error;
      });

    await this._services.users.myself._ensureFetched();

    Client._supportedPushChannels.forEach((channelType) =>
      this._subscribeToPushNotifications(channelType)
    );
    this._services.typingIndicator.initialize();

    this._services.mcsClient = new McsClient(
      this._fpaToken,
      this._configuration.links.mediaService,
      this._configuration.links.mediaSetService,
      {
        ...this._options,
        transport: undefined,
      }
    );

    this._resolveEnsureReady();
    this.emit(Client.stateChanged, "initialized");
    this.emit(Client.initialized);
  }

  /**
   * Subscribe to push notifications.
   * @param channelType The channel type to subscribe to.
   */
  private _subscribeToPushNotifications(channelType: NotificationsChannelType) {
    [
      NotificationTypes.NEW_MESSAGE,
      NotificationTypes.ADDED_TO_CONVERSATION,
      NotificationTypes.REMOVED_FROM_CONVERSATION,
      NotificationTypes.TYPING_INDICATOR,
      NotificationTypes.CONSUMPTION_UPDATE,
    ].forEach((messageType) => {
      this._services.notificationClient.subscribe(channelType, messageType);
    });
  }

  /**
   * Unsubscribe from push notifications.
   * @param channelType The channel type to unsubscribe from.
   */
  private _unsubscribeFromPushNotifications(
    channelType: NotificationsChannelType
  ) {
    [
      NotificationTypes.NEW_MESSAGE,
      NotificationTypes.ADDED_TO_CONVERSATION,
      NotificationTypes.REMOVED_FROM_CONVERSATION,
      NotificationTypes.TYPING_INDICATOR,
      NotificationTypes.CONSUMPTION_UPDATE,
    ].forEach((messageType) => {
      this._services.notificationClient.unsubscribe(channelType, messageType);
    });
  }

  /**
   * Initialize the ensureReady promise.
   */
  private _initializeEnsureReady(throwErrorsAlways: boolean): void {
    this._ensureReady = new Promise<void>((resolve, reject) => {
      this._resolveEnsureReady = resolve;
      this._rejectEnsureReady = reject;
    }).catch((error) => {
      if (throwErrorsAlways) {
        throw error;
      } else {
        return void 0;
      }
    });
  }
}

export {
  Client,
  State,
  ConnectionState,
  NotificationsChannelType,
  LogLevel,
  ClientOptions,
  CreateConversationOptions,
};
